<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <h2 class="text-2xl font-weight-semibold">
              {{ $t('general.name') }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field v-model="username" outlined label="Username" class="mb-3" hide-details="auto"></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-btn :loading="processing" block color="primary" class="mt-6" @click="Login"> Login </v-btn>
          </v-form>
        </v-card-text>
      </v-card>

      <v-snackbar v-model="loginError" timeout="4000" color="error" top>
        <div class="d-flex align-center">
          <v-icon class="mr-2" color="white">
            {{ icons.mdiAlertOctagon }}
          </v-icon>
          Incorrect Username or Password
        </div>
        <template #action="{ attrs }">
          <v-btn color="white" icon v-bind="attrs" @click="loginError = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline, mdiClose, mdiAlertOctagon } from '@mdi/js'
import { postData } from '@/api'
import Cookies from 'js-cookie'

export default {
  data: () => ({
    isPasswordVisible: false,
    username: '',
    password: '',
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiClose,
      mdiAlertOctagon,
    },
    processing: false,
    loginError: false,
  }),
  methods: {
    async Login() {
      if (!this.processing) {
        this.processing = true

        const payload = {
          username: this.username,
          password: this.password,
        }

        console.log(payload)
        try {
          let params = new URLSearchParams()
          params.set('admin_login', JSON.stringify(payload))
          const data = await postData(params)

          this.$func.log('---login---')
          this.$func.log(data)

          Cookies.set('CARECONNECT_CMS_LID', data, {
            path: '/',
            expires: 120,
            sameSite: 'strict',
            secure: true,
          })
          this.$router.replace({ name: 'Worker' })
        } catch (error) {
          this.$func.log(error)
          this.loginError = true
          this.processing = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
